import React from 'react';
import '../styles/FreeShipping.css'; // Create a CSS file for styles

const FreeShipping = () => {
  return (
    <div className="free-shipping-bar">
      FREE SHIPPING PAN INDIA | SHIPPING IN 14 DAYS
    </div>
  );
}

export default FreeShipping;
